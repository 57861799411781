import React from 'react'
import { DialogProps } from '@mui/material/Dialog'
import { Close as CloseIcon } from 'icons'
import { useViewport } from 'hooks'
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from 'components'
import { UploadDocumentForm, UploadDocumentFormProps } from 'compositions/Documents/UploadDocumentForm'

export type UploadDocumentDialogProps = Omit<DialogProps, 'onChange'> & UploadDocumentFormProps & { onClose: () => void }

export const UploadDocumentDialog: React.FC<UploadDocumentDialogProps> = ({ open, onClose, form, documentTypeOptions, withoutExpiration }) => {
  const { isDesktop } = useViewport()

  return <Dialog fullScreen={!isDesktop} open={open} onClose={onClose}>
    <DialogTitle sx={{ textAlign: 'center' }}>
      Upload a document
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <Box display="flex" flexDirection="column" rowGap={2} width="100%" minWidth={{ md: '400px' }}>
        <UploadDocumentForm form={form} documentTypeOptions={documentTypeOptions} withoutExpiration={withoutExpiration} />
      </Box>
    </DialogContent>
  </Dialog>
}

export default UploadDocumentDialog
