import React, { PropsWithChildren } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Text, Button, Link } from 'components'
import { useViewport } from 'hooks'
export type NavBarProps = React.PropsWithChildren<{
  toggleDrawer: (e: React.MouseEvent) => void
}>

export type NavBarLogoProps = { logo: React.ReactElement }

export const NavBar: React.FC<PropsWithChildren<NavBarProps & NavBarLogoProps>> = ({ children, logo, toggleDrawer }) => {
  const { isDesktop } = useViewport()

  return <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar sx={{ display: 'flex', justifyConte: 'space-between', alignItems: 'center' }}>
      <Box sx={{ flex: isDesktop ? '0 0 40%' : '0 0 48px', maxWidth: '40%' }} display="flex">
        <Button color="inherit" variant="text" onClick={toggleDrawer} fullWidth={false} sx={{ mr: 1 }}>
          <MenuIcon fontSize='large' />
        </Button>

        <Link component={RouterLink} to="/" color="inherit" variant="button">
          <Text variant="h6" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            { logo }
          </Text>
        </Link>
      </Box>

      <Box sx={{ flex: '1 1 auto' }} textAlign="center">
        <Box mx="auto" width="auto" display="inline-block">
          { children }
        </Box>
      </Box>

      <Box sx={{ flex: isDesktop ? '0 0 40%' : '0 0 48px', maxWidth: '40%' }} display="flex" justifySelf="flex-end" justifyContent="flex-end">
        <Box sx={(theme) => ({
          minWidth: theme.spacing(8)
        })}>
          <Box id="NavBarPortal">
            {/* Portal for rendering filters, etc. on mobile */}
          </Box>
        </Box>
      </Box>
    </Toolbar>
  </AppBar>
}

export default NavBar
