import React, { PropsWithChildren } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Account as AccountIcon,
  Logout as LogoutIcon,
  ManageAccount as ManageAccountIcon
} from 'icons'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { useLocation } from 'react-router'
import { Box, MenuLink, Text } from 'components'
import { useMe, useLogout } from 'hooks'
import { SchoolLocation, Term, VendorLocation } from 'graphql/schema/graphql'

const MenuBox = styled(Box)({
  position: 'relative',
  ['a']: {
    width: '100%'
  }
})

const AccountBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

export type FooterProps = PropsWithChildren<{
  onClick: React.MouseEventHandler,
  me: ReturnType<typeof useMe>,
  currentLocation?: SchoolLocation | VendorLocation,
  currentTerm?: Term
  AccountMenuItems: React.FC<{ me: ReturnType<typeof useMe>, onClick?: React.MouseEventHandler }>
  accountMenuOpen: boolean
  setAccountMenuOpen: (open: boolean) => void
}>

export const Footer: React.FC<FooterProps> = ({ me, currentLocation, currentTerm, AccountMenuItems, onClick, accountMenuOpen, setAccountMenuOpen }) => {
  const { me: user } = me
  const logout = useLogout(false)
  const toggleMenu: React.MouseEventHandler = () => setAccountMenuOpen(!accountMenuOpen)
  const location = useLocation()

  if (!user) return null

  return <Box>
    <Divider />

    <AccountBox pl={2} pr={3} py={2} onClick={toggleMenu}>
      <Box flexGrow="0" sx={{ verticalAlign: 'middle' }}>
        <Text color="grey" variant="h3">
          <AccountIcon fontSize="inherit" />
        </Text>
      </Box>
      <Box flexGrow="1" ml={1}>
        <Box display="flex" flexDirection="column">
          <Box position="relative">
            <Text variant="h6">{`${user.firstName} ${user.lastName}`}</Text>
            <Box position="absolute" top="0" right="0">
              <Text color="grey">
                { !accountMenuOpen && <ArrowUpIcon fontSize="small" /> }
                { accountMenuOpen && <ArrowDownIcon fontSize="small" /> }
              </Text>
            </Box>
          </Box>
          { currentLocation && <>
            <Box>
              <Text variant="subtitle1">{ currentLocation.organization.name }</Text>
            </Box>
            <Box>
              { currentLocation.name !== currentLocation.organization.name && <Text variant="subtitle2">{ currentLocation.name }</Text> }
            </Box>
            { currentTerm && <Box>
              <Text variant="caption">{ currentTerm.name }</Text>
            </Box> }
          </> }
        </Box>
      </Box>
    </AccountBox>


    <MenuBox display={accountMenuOpen ? 'block' : 'none'}>
      <Divider />

      <MenuList id="account-menu">
        { AccountMenuItems && <AccountMenuItems me={me} onClick={onClick} /> }
        { AccountMenuItems && <Divider /> }

        <MenuItem>
          <MenuLink component={RouterLink} to="/profile" color={location.pathname === "/profile" ? "primary" : "text.primary"} onClick={onClick} onTouchEnd={onClick}>
            <ManageAccountIcon sx={{ marginRight: 1 }} />
            Manage Account
          </MenuLink>
        </MenuItem>

        <MenuItem>
          <MenuLink href="/logout" color="inherit" onClick={logout} onTouchEnd={logout}>
            <LogoutIcon sx={{ marginRight: 1 }} />
            Logout
          </MenuLink>
        </MenuItem>
      </MenuList>
    </MenuBox>

  </Box>
}

export default Footer
