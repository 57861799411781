import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import MenuList from '@mui/material/MenuList'
import Toolbar from '@mui/material/Toolbar'
import { Login as LoginIcon } from 'icons'
import { useMe, useViewport } from 'hooks'
import {
  Box,
  // Link,
  // Text,
  MenuLink
} from 'components'
import Footer from './Footer'
import { SchoolLocation, Term, VendorLocation } from 'graphql/schema/graphql'

export type NavDrawerProps = {
  toggleDrawer: (open: boolean) => void
  accountMenuOpen: boolean
  setAccountMenuOpen: (open: boolean) => void
  AccountMenuItems?: React.FC<{ me: ReturnType<typeof useMe> }>
  NavigationMenuItems?: React.FC<{ me: ReturnType<typeof useMe> }>
  currentLocation?: SchoolLocation | VendorLocation
  currentTerm?: Term
} & DrawerProps

type DrawerContainerProps = Pick<NavDrawerProps, "sx" | "toggleDrawer" | "variant" | "AccountMenuItems" | "NavigationMenuItems" | "currentLocation" | "currentTerm" | "accountMenuOpen" | "setAccountMenuOpen" | "open">

const NavigationMenu = styled(MenuList)(({ theme }) => ({
  ['.MuiMenuItem-root']: {
    paddingLeft: theme.spacing(6),
  },
  ['a']: {
    width: '100%'
  }
}))

const DrawerContainer: React.FC<React.PropsWithChildren<DrawerContainerProps>> = ({ open, variant, currentLocation, currentTerm, NavigationMenuItems, AccountMenuItems, accountMenuOpen, toggleDrawer, setAccountMenuOpen, children, ...props }) => {
  // const { isDesktop } = useViewport()
  const me = useMe()
  const { token, me: user } = me


  const onMenuClick: React.MouseEventHandler = () => {
    if (variant === "temporary") toggleDrawer(false)
  }

  if (variant === "persistent" && !open) return null

  return <Drawer {...props} open={open} variant={variant} onClose={() => toggleDrawer(false)}>
    <Toolbar />

    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        {/* { !isDesktop && currentLocation && <Box px={4} py={2} borderBottom="1px solid #ccc">
          <Link component={RouterLink} to="/" onClick={onMenuClick} color="inherit">
            <Text variant="h6" textAlign="center">{ currentLocation.name === currentLocation.organization.name ? currentLocation.name : `${currentLocation.organization.name} ${currentLocation.name}` } { currentTerm?.name }</Text>
          </Link>
        </Box> } */}

        <NavigationMenu onClick={onMenuClick}>
          { (token && user) && NavigationMenuItems && <NavigationMenuItems me={me} /> }

          { !token &&
            <MenuItem>
              <MenuLink component={RouterLink} to="login">
                <LoginIcon sx={{ marginRight: 1 }} />
                Login
              </MenuLink>
            </MenuItem>
          }
        </NavigationMenu>
      </Box>

      <Box>
        { (token && user) && children }
        <Footer me={me} currentLocation={currentLocation} currentTerm={currentTerm} onClick={onMenuClick} AccountMenuItems={AccountMenuItems} accountMenuOpen={accountMenuOpen} setAccountMenuOpen={setAccountMenuOpen} />
      </Box>
    </Box>
  </Drawer>
}

export const NavDrawer: React.FC<NavDrawerProps> = (props) => {
  const { isDesktop } = useViewport()

  return <>
    <DrawerContainer {...props} variant={isDesktop ? "persistent" : "temporary"} />
  </>
}

export default NavDrawer