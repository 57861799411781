import React from 'react'
import { Box, Text, Page } from 'components'
import { ChangePasswordForm, UpdateProfileForm } from '../'
import { useMe, useChangePasswordForm, useUpdateMeForm } from 'hooks'

export const ManageProfile: React.FC = () => {
  const { me } = useMe()
  const updateMeForm = useUpdateMeForm(me)
  const changePasswordForm = useChangePasswordForm()

  return <Page>
    <Text variant="h4">Manage your profile</Text>

    <Box mb={4}>
      <Text variant="h6" mb={2}>Update profile</Text>
      <UpdateProfileForm me={me} form={updateMeForm} />
    </Box>

    <Box>
      <Text variant="h6" mb={2}>Change password</Text>
      <ChangePasswordForm form={changePasswordForm} />
    </Box>
  </Page>
}

export default ManageProfile
